


























import { BootstrapButton } from '@movecloser/ui-core'
import { Component, Mixins, Prop } from 'vue-property-decorator'

import { Alignmentable, collapse, Colorable } from '../../../../extensions'

import { AbstractModuleUiPresentation } from '../../_abstract'

import { TextModuleContent } from '../Text.contracts'

/**
 * Presentational component for the `TextModuleUi`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<TextModuleUiPresentation>({
  name: 'TextModuleUiPresentation',
  components: {
    BootstrapButton,
    UiMarkdown: () => import(
      /* webpackChunkName: "atoms/UiMarkdown" */
      '../../../atoms/UiMarkdown/UiMarkdown.vue'
    )
  },
  directives: { collapse }
})
export class TextModuleUiPresentation extends Mixins<
  AbstractModuleUiPresentation, Alignmentable, Colorable
>(AbstractModuleUiPresentation, Alignmentable, Colorable) {
  /**
   * @see TextModuleContent.content
   */
  @Prop({ type: String, required: true })
  public readonly content!: TextModuleContent['content']

  /**
   * @see TextModuleContent.extendedContent
   */
  @Prop({ type: String, required: false })
  public readonly extendedContent!: TextModuleContent['extendedContent']

  /**
   * Determines whether the extended content part is collapsed at a given moment.
   *
   * @see extendedContent
   */
  public isExtendedContentCollapsed: boolean = true

  /**
   * Determines whether the component has been provided with the correct `content` prop.
   */
  public get hasContent (): boolean {
    return typeof this.content === 'string' && this.content.length > 0
  }

  /**
   * Determines whether the component has been provided with the correct `extendedContent` prop.
   */
  public get hasExtendedContent (): boolean {
    return typeof this.extendedContent === 'string' && this.extendedContent.length > 0
  }

  /**
   * Handles the `@click` event on the extended content's toggler button.
   */
  public onExtendedContentTogglerClick (): void {
    this.revealExtendedContent()
  }

  /**
   * Reveals the extended content part.
   */
  private revealExtendedContent (): void {
    this.isExtendedContentCollapsed = false
  }
}

export default TextModuleUiPresentation
