







import { AsyncComponent, VueConstructor } from 'vue'
import { Component, InjectReactive, Prop } from 'vue-property-decorator'
import { IEventbus } from '@movecloser/front-core'

import { log } from '../../../../support'

import {
  ALL_CONTAINERS_MOUNTED_INJECTION_KEY,
  UI_CONTAINER_ID_ATTR_PREFIX,
  UI_CONTAINER_MOUNTED_EVENTBUS_EVENT_NAME
} from '../../../atoms'

import { AbstractModuleUiPresentation } from '../../_abstract'

import {
  TabsModuleContainersRegistry,
  TabsModuleContent,
  TabsModuleVersion
} from '../Tabs.contracts'

import { tabsModuleVersionComponentRegistry } from './Tabs.ui.config'

/**
 * Presentational component for the `TabsModuleUi`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<TabsModuleUiPresentation>({
  name: 'TabsModuleUiPresentation',
  mounted (): void {
    this.catchContainers()
      .then((containers: TabsModuleContainersRegistry) => {
        this.containers = containers
      })
      .catch(error => {
        log(error, 'error')
      })
  }
})
export class TabsModuleUiPresentation extends AbstractModuleUiPresentation {
  /**
   * An instance of the `EventBus` service.
   */
  @Prop({ type: Object, required: true })
  private readonly eventBus!: IEventbus

  /**
   * @see TabsModuleContent.tabs
   */
  @Prop({ type: Array, required: true })
  public readonly tabs!: TabsModuleContent['tabs']

  /**
   * Version of the module. Determines which Vue component
   * will be used to render the offers.
   *
   * @see TabsModuleVersion
   */
  @Prop({ type: String, required: true })
  private readonly version!: TabsModuleVersion

  /**
   * Determines whether all containers have been mounted yet.
   */
  @InjectReactive({ from: ALL_CONTAINERS_MOUNTED_INJECTION_KEY, default: false })
  private readonly allContainersMounted!: boolean

  /**
   * List of key-value pairs representing the associated containers along with their root HTML elements.
   */
  public containers: TabsModuleContainersRegistry | null = null

  /**
   * Vue component that should be used to render the job offers.
   */
  public get component (): VueConstructor | AsyncComponent | undefined {
    const component = tabsModuleVersionComponentRegistry[this.version]

    if (typeof component === 'undefined') {
      log(`TabsModuleUiPresentation.component(): There's no Vue component associated with the [${this.version}] TabsModuleVersion!`, 'error')
      return
    }

    return component
  }

  /**
   * Determines whether the component has all the data it needs for a successful render.
   */
  public get shouldRender (): boolean {
    return this.hasComponent && this.hasTabs && this.hasContainers
  }

  /**
   * Only those tabs which associated containers have been successfully found in the DOM.
   *
   * @see containers
   * @see catchContainers()
   */
  public get tabsWithContainers (): TabsModuleContent['tabs'] {
    if (this.containers === null) {
      return []
    }

    return this.tabs.filter(tab => {
      // @ts-expect-error - TS does not recognise the above `null` check :)
      return Object.keys(this.containers).includes(tab.containerId)
    })
  }

  /**
   * Catches the HTML elements associated with the tabs' containers.
   */
  private async catchContainers (): Promise<TabsModuleContainersRegistry> {
    // We have to use the `Promise` here, as the containers are being mounted
    // in a mainly random order. In other words, there's no guarantee that in the time
    // of running this method all the containers will be mounted and their root HTML elements
    // will be present in the DOM.
    const possibleContainers: PromiseSettledResult<HTMLDivElement>[] = await Promise.allSettled(
      // Loop through all the tabs and create a new `Promise` object for each of them.
      this.tabs.map<Promise<HTMLDivElement>>(({ containerId }) => {
        return new Promise<HTMLDivElement>((resolve, reject) => {
          // Try to catch the corresponding container element within the DOM.
          let container: HTMLDivElement | null = null
          const selector: string = `#${UI_CONTAINER_ID_ATTR_PREFIX}${containerId}`

          /**
           * Tries to catch the container element within the DOM.
           *
           * @see container
           */
          const catchContainer = (): void => {
            container = document.querySelector<HTMLDivElement>(selector)
          }

          catchContainer()

          // If an attempt was a success (i.e. HTML element has been successfully queried)
          // resolve the `Promise` and return the caught container element.
          if (container !== null) {
            return resolve(container)
          }

          // Else, if the `querySelector()` returned `null`,
          // start watching the event bus for the upcoming events
          // with `name` property set to `UI_CONTAINER_MOUNTED_EVENTBUS_EVENT_NAME`.
          // This kind of event is being emitted when the `<UiContainer>` component gets mounted.
          this.eventBus.handle<{ id: string }>(
            UI_CONTAINER_MOUNTED_EVENTBUS_EVENT_NAME, event => {
              // When an event is intercepted, check if the corresponding `id` payload property
              // is equal to the `containerId` variable. If true, it means that the emitted event
              // relates to the same container that the tab is assigned to.
              if (event.payload?.id === containerId) {
                // Try to catch the corresponding container element within the DOM.
                catchContainer()

                if (container === null) {
                  return reject(new Error(`Associated <UiContainer> with the ID of [${containerId}] has been mounted, but couldn't be found in DOM!`))
                }

                // If an attempt was a success (i.e. HTML element has been successfully queried)
                // resolve the `Promise` and return the caught container element.
                return resolve(container)
              }
            })

          /**
           * Rejects the promise when all containers have been successfully mounted.
           *
           * @see reject
           */
          const rejectWhenAllContainersMounted = () => {
            if (this.allContainersMounted) {
              return reject(new Error(`Associated <UiContainer> with the ID of [${containerId}] hasn't been mounted. Aborting.`))
            }

            this.$watch('allContainersMounted', rejectWhenAllContainersMounted)
          }

          return rejectWhenAllContainersMounted()
        })
      }))

    return this.tabs.reduce<TabsModuleContainersRegistry>((acc, { containerId }) => {
      const foundContainers: HTMLDivElement[] = []

      possibleContainers.forEach(container => {
        if (container.status === 'fulfilled') {
          foundContainers.push(container.value)
        }
      })

      const container: HTMLDivElement | undefined =
        foundContainers.find(container => container.id.includes(containerId))

      if (typeof container === 'undefined') {
        log(`Failed to find the container with the ID of [${containerId}]!`, 'error')
        return acc
      }

      return { ...acc, [containerId]: container }
    }, {})
  }

  /**
   * Determines whether the Vue component applicable for the given `TabsModuleVersion`
   * has been successfully resolved from the `tabsModuleVersionComponentRegistry`.
   */
  private get hasComponent (): boolean {
    return typeof this.component === 'function'
  }

  /**
   * Determines whether the component has successfully populated the containers registry.
   */
  private get hasContainers (): boolean {
    return this.containers !== null && Object.entries(this.containers).length > 0
  }

  /**
   * Determines whether the component has been provided with the correct `tabs` prop.
   */
  private get hasTabs (): boolean {
    return typeof this.tabs !== 'undefined' &&
      Array.isArray(this.tabs) &&
      this.tabs.length > 0
  }
}

export default TabsModuleUiPresentation
