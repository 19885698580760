








import { Component } from 'vue-property-decorator'

import { AbstractModuleUi } from '../../_abstract'

import { HeadingModule } from '../Heading.contracts'

/**
 * Container component for the `HeadingModuleUi`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<HeadingModuleUi>({
  name: 'HeadingModuleUi',
  components: {
    HeadingModuleUiPresentation: () => import(
      /* webpackChunkName: "modules/shared" */
      './Heading.ui.presentation.vue'
    )
  }
})
export class HeadingModuleUi extends AbstractModuleUi<HeadingModule> {}

export default HeadingModuleUi
