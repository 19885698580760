








import { Component } from 'vue-property-decorator'

import { isUnresolvedLink, LinkWithLabel } from '../../../../contracts'
import { log } from '../../../../support'

import { AbstractModuleUi } from '../../_abstract'

import { ButtonModule, ResolvedButtonModuleContent } from '../Button.contracts'

/**
 * Container component for the `ButtonModuleUi`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<ButtonModuleUi>({
  name: 'ButtonModuleUi',
  components: {
    ButtonModuleUiPresentation: () => import(
      /* webpackChunkName: "modules/shared" */
      './Button.ui.presentation.vue'
    )
  }
})
export class ButtonModuleUi extends AbstractModuleUi<ButtonModule> {
  /**
   * `this.data` with all related data already resolved.
   *
   * @see AbstractModuleUi.data
   * @see setInitialResolvedData()
   */
  public resolvedData: ResolvedButtonModuleContent | null = null

  /**
   * @inheritDoc
   */
  public async fetchRelated (): Promise<void> {
    this.setInitialResolvedData()
    await this._fetchLink()
  }

  /**
   * Determines whether the component has all the data it needs for a successful render.
   */
  public get shouldRender (): boolean {
    return this.hasContent &&
      this.resolvedData !== null &&
      this.resolvedData.link !== null
  }

  /**
   * Fetches the details of the associated link.
   *
   * @see ButtonModuleContent.link
   */
  private async _fetchLink (): Promise<void> {
    const link = this.data.content.link

    if (!isUnresolvedLink(link)) {
      this.resolvedData = { ...this.resolvedData, link }
      return
    }

    const resolvedLink: LinkWithLabel = await this.fetchLink(link)

    this.resolvedData = {
      ...this.resolvedData,
      link: resolvedLink
    }
  }

  /**
   * Sets the initial value for `this.resolvedData` property.
   *
   * @see resolvedData
   */
  private setInitialResolvedData (): void {
    try {
      this.resolvedData = { ...this.data.content, link: null }
    } catch (error) {
      const message: string = 'ButtonModuleUi.setInitialData(): Failed to set the initial data for [this.resolvedData] property!'
      log([message, error], 'error')
    }
  }
}

export default ButtonModuleUi
