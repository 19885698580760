








import { BootstrapImageProps, Link } from '@movecloser/ui-core'
import { Component, Prop, Provide } from 'vue-property-decorator'

import { AbstractModuleUiPresentation } from '../../_abstract'
import { Heading } from '../../../../contracts'
import { CardBackgroundColor } from '../Card.contracts'

/**
 * Presentational component for the `CardModuleUi`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<CardModuleUiPresentation>({
  name: 'CardModuleUiPresentation',
  components: {
    Card: () => import(
      /* webpackChunkName: "molecules/Card" */
      '../../../molecules/Card/Card.vue'
    )
  }
})
export class CardModuleUiPresentation extends AbstractModuleUiPresentation {
  /**
   * Value for the `background` CSS property of the root `<figure>` element.
   */
  @Prop({ type: String, required: false, default: 'transparent' })
  public readonly background!: CardBackgroundColor

  /**
   * Card's body (with Markdown support (will be parsed to HTML)).
   */
  @Prop({ type: String, required: true })
  public readonly body!: string

  /**
   * Determines whether the image should be cropped to the default shape.
   */
  @Prop({ type: Boolean, required: false, default: true })
  public readonly cropImage!: boolean

  /**
   * Card's heading.
   */
  @Prop({ type: Object, required: false })
  public readonly heading?: Heading

  /**
   * Card's image.
   */
  @Prop({ type: Object, required: false })
  public readonly image?: BootstrapImageProps

  /**
   * Array of labels to render.
   */
  @Prop({ type: Array, required: false })
  public readonly labels?: string[]

  /**
   * Link associated with the card.
   * User will be redirected to this target when he clicks the card.
   */
  @Prop({ type: Object, required: true })
  public readonly link!: Link

  /**
   * Determines whether the card's body should end with the "read more" button.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public readonly showReadMoreBtn!: boolean

  /**
   * Determines whether the card's body should be wrapped around with a border.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public readonly withBorder!: boolean

  /**
   * Determines current site domain.
   */
  @Provide('linkFollowedDomains')
  public followedDomains: string[] = [process.env.VUE_APP_BNP_MAIN_PAGE ?? '']

  /**
   * Determines whether the component has been provided with the correct `body` prop.
   */
  public get hasBody (): boolean {
    return typeof this.body === 'string' && this.body.length > 0
  }

  /**
   * Determines whether the component has been provided with the correct `link` prop.
   */
  public get hasLink (): boolean {
    return typeof this.link !== 'undefined' && typeof this.link.target !== 'undefined'
  }

  public get headingText (): string | undefined {
    if (!this.heading) {
      return
    }
    return this.heading.text
  }

  public get headingLevel (): number | undefined {
    if (!this.heading) {
      return
    }
    return this.heading.level
  }
}

export default CardModuleUiPresentation
