













import { Component, Mixins, Prop } from 'vue-property-decorator'

import { Alignmentable, Colorable, UseNonce } from '../../../../extensions'
import { Color } from '../../../../contracts'

import { AbstractModuleUiPresentation } from '../../_abstract'

import { HeadingFont, HeadingModuleContent } from '../Heading.contracts'

/**
 * Presentational component for the `HeadingModuleUi`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<HeadingModuleUiPresentation>({
  name: 'HeadingModuleUiPresentation',
  components: {
    UiHeading: () => import(
      /* webpackChunkName: "atoms/UiHeading" */
      '../../../atoms/UiHeading/UiHeading.vue'
    ),
    UiMarkdown: () => import(
      /* webpackChunkName: "atoms/UiMarkdown" */
      '../../../atoms/UiMarkdown/UiMarkdown.vue'
    )
  }
})
export class HeadingModuleUiPresentation extends Mixins<
  AbstractModuleUiPresentation, Alignmentable, Colorable, UseNonce
>(AbstractModuleUiPresentation, Alignmentable, Colorable, UseNonce) {
  /**
   * @see HeadingModuleContent.level
   */
  @Prop({ type: Number, required: true })
  public readonly level!: HeadingModuleContent['level']

  /**
   * @see HeadingModuleContent.displayLevel
   */
  @Prop({ type: Number, required: false })
  public readonly displayLevel!: HeadingModuleContent['displayLevel']

  /**
   * @see HeadingModuleContent.prependText
   */
  @Prop({ type: String, required: false })
  public readonly prependText?: HeadingModuleContent['prependText']

  /**
   * @see HeadingModuleContent.text
   */
  @Prop({ type: String, required: true })
  public readonly text!: HeadingModuleContent['text']

  /**
   * @see HeadingModuleContent.zoom
   */
  @Prop({ type: Number, required: false, default: 100 })
  public readonly zoom!: HeadingModuleContent['zoom']

  /**
   * @see HeadingModuleContent.font
   */
  @Prop({ type: String, required: false, default: HeadingFont.Default })
  public readonly font!: HeadingModuleContent['font']

  @Prop({ type: String, required: true })
  public readonly htmlId!: string

  /**
   * Registry that binds the `Color` with the applicable CSS class.
   */
  public readonly colorClassNameRegistry = {
    [Color.Black]: 'text-black',
    [Color.Body]: '',
    [Color.White]: 'text-white'
  }

  /**
   * Determines whether the component has been provided with the correct `level` prop.
   */
  public get hasLevel (): boolean {
    return typeof this.level === 'number'
  }

  /**
   * Determines whether the component has been provided with the correct `prependText` prop.
   */
  public get hasPrependText (): boolean {
    return typeof this.prependText === 'string' && this.prependText.length > 0
  }

  /**
   * Determines whether the component has been provided with the correct `text` prop.
   */
  public get hasText (): boolean {
    return typeof this.text === 'string' && this.text.length > 0
  }

  /**
   * Get heading class by level and display level.
   */
  public get displaySizeClass (): string {
    if (this.displayLevel !== undefined) {
      return `--h${this.displayLevel}`
    }

    return `--h${this.level}`
  }

  public get style (): string {
    return `
      #${this.htmlId} .Heading__content {
        font-size: ${this.zoom}%
      }
    `
  }
}

export default HeadingModuleUiPresentation
