








import { Component } from 'vue-property-decorator'

import { isLink, log } from '../../../../support'
import { isUnresolvedLink, LinkWithLabel } from '../../../../contracts'

import { TileBehavior } from '../../../molecules'

import { AbstractModuleUi } from '../../_abstract'

import { TileModule } from '../Tile.contracts'

/**
 * Container component for the `TileModuleUi`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<TileModuleUi>({
  name: 'TileModuleUi',
  components: {
    TileModuleUiPresentation: () => import(
      /* webpackChunkName: "modules/shared" */
      './Tile.ui.presentation.vue'
    )
  }
})
export class TileModuleUi extends AbstractModuleUi<TileModule> {
  /**
   * Tile's link from `this.module.content` with all related data already resolved and ready to use.
   */
  public link: LinkWithLabel | null = null

  /**
   * @inheritDoc
   */
  public async fetchRelated (): Promise<void> {
    await this.fetchTileLink()
  }

  /**
   * Determines whether the component has all the data it needs for a successful render.
   */
  public get shouldRender (): boolean {
    if (!this.hasContent) {
      return false
    }

    if (this.data.content.behavior === TileBehavior.Link) {
      return this.hasLink
    }

    return true
  }

  /**
   * Fetches internal link from the `RelatedService`.
   */
  private async fetchTileLink (): Promise<void> {
    const unknownLink = this.data.content.link

    if (isLink(unknownLink)) {
      this.link = unknownLink
      return
    }

    if (isUnresolvedLink(unknownLink)) {
      try {
        this.link = await this.fetchLink(unknownLink)
      } catch (error) {
        const message: string =
          'TileModuleUi.fetchTileLink(): Failed to fetch the related link from the [RelatedService]!'
        log(message, 'error')
      }
    }
  }

  /**
   * Determines whether the `link` property has an appropriate data inside.
   *
   * @see link
   * @see fetchTileLink()
   */
  private get hasLink (): boolean {
    return isLink(this.link)
  }
}

export default TileModuleUi
