









import { Component, Prop, Provide } from 'vue-property-decorator'
import { Link } from '@movecloser/ui-core'

import { TileBehavior, TileIconColor, TileIconPlacement, TileProps } from '../../../molecules'
import { Heading } from '../../../../contracts'

import { AbstractModuleUiPresentation } from '../../_abstract'

/**
 * Presentational component for the `TileModuleUi`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<TileModuleUiPresentation>({
  name: 'TileModuleUiPresentation',
  components: {
    Tile: () => import(
      /* webpackChunkName: "molecules/Tile" */
      '../../../molecules/Tile/Tile.vue'
    )
  }
})
export class TileModuleUiPresentation extends AbstractModuleUiPresentation {
  /**
   * @see TileProps.behavior
   */
  @Prop({ type: String, required: true })
  public readonly behavior!: TileBehavior

  /**
   * @see TileProps.body
   */
  @Prop({ type: String, required: false })
  public readonly body?: string

  /**
   * @see TileProps.border
   */
  @Prop({ type: Boolean, required: false, default: false })
  public readonly border!: boolean

  /**
   * @see TileProps.contentAlignment
   */
  @Prop({ type: String, required: false })
  public readonly contentAlignment?: TileProps['contentAlignment']

  /**
   * @see TileProps.dataCircle
   */
  @Prop({ type: Object, required: false })
  public readonly dataCircle?: TileProps['dataCircle']

  /**
   * @see TileProps.extendedBody
   */
  @Prop({ type: String, required: false })
  public readonly extendedBody?: string

  /**
   * @see TileProps.heading
   */
  @Prop({ type: Object, required: true })
  public readonly heading!: Heading

  /**
   * @see TileProps.headingAlignment
   */
  @Prop({ type: String, required: false })
  public readonly headingAlignment?: TileProps['headingAlignment']

  /**
   * @see TileProps.iconColor
   */
  @Prop({ type: String, required: false })
  public readonly iconColor?: TileIconColor

  /**
   * @see TileProps.iconName
   */
  @Prop({ type: String, required: false })
  public readonly iconName?: string

  /**
   * @see TileProps.iconPlacement
   */
  @Prop({ type: String, required: false, default: TileIconPlacement.Top })
  public readonly iconPlacement!: TileIconPlacement

  /**
   * @see TileProps.large
   */
  @Prop({ type: Boolean, required: false, default: false })
  public readonly large!: boolean

  /**
   * @see TileProps.link
   */
  @Prop({ type: Object, required: false })
  public readonly link?: Link

  /**
   * Determines whether the link should have button-like look.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public readonly linkAsButton!: boolean

  /**
   * @see TileProps.rounded
   */
  @Prop({ type: Boolean, required: false, default: true })
  public readonly rounded!: boolean

  /**
   * @see TileProps.shadow
   */
  @Prop({ type: Boolean, required: false, default: false })
  public readonly shadow!: boolean

  /**
   * @see TileProps.tag
   */
  @Prop({ type: String, required: false, default: 'div' })
  public readonly tag!: string

  /**
   * @see TileProps.transparent
   */
  @Prop({ type: Boolean, required: false, default: false })
  public readonly transparent!: boolean

  /**
   * Determines current site domain.
   */
  @Provide('linkFollowedDomains')
  public followedDomains: string[] = [process.env.VUE_APP_BNP_MAIN_PAGE ?? '']

  /**
   * @see Tile.linkClassName
   */
  public get linkClassName (): TileProps['linkClassName'] {
    return this.linkAsButton ? 'btn btn-outline-primary' : ''
  }
}

export default TileModuleUiPresentation
