








import { BootstrapImageProps } from '@movecloser/ui-core'
import { Component } from 'vue-property-decorator'

import { isRelated } from '../../../../services'
import { log } from '../../../../support'
import { isUnresolvedLink, ResolvedLink } from '../../../../contracts'

import { AbstractModuleUi } from '../../_abstract'

import { CardModule } from '../Card.contracts'

/**
 * Container component for the `CardModuleUi`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<CardModuleUi>({
  name: 'CardModuleUi',
  components: {
    CardModuleUiPresentation: () => import(
      /* webpackChunkName: "modules/shared" */
      './Card.ui.presentation.vue'
    )
  }
})
export class CardModuleUi extends AbstractModuleUi<CardModule> {
  /**
   * Card's image from `this.module.content` with all related data already resolved and ready to use.
   */
  public image: BootstrapImageProps | null = null

  /**
   * Card's image from `this.module.content` with all related data already resolved and ready to use.
   */
  public link: ResolvedLink | null = null

  /**
   * @inheritDoc
   */
  public async fetchRelated (): Promise<void> {
    await Promise.allSettled([
      this.fetchCardImage(),
      await this._fetchLink()
    ])
  }

  /**
   * Determines whether the component has all the data it needs for a successful render.
   */
  public get shouldRender (): boolean {
    return this.hasContent &&
      this.link !== null
  }

  /**
   * Fetches card's image from the `RelatedService`.
   */
  private async _fetchLink (): Promise<void> {
    const link = this.data.content.link

    if (link === null) {
      return
    }

    if (!isUnresolvedLink(link)) {
      this.link = link
      return
    }

    try {
      this.link = await this.fetchLink(link)
    } catch (error) {
      const message: string =
        'CardModuleUi._fetchLink(): Failed to fetch the related link from the [RelatedService]!'
      log(message, 'error')
    }
  }

  /**
   * Fetches card's image from the `RelatedService`.
   */
  private async fetchCardImage (): Promise<void> {
    const unresolvedImage = this.data.content.image

    if (!isRelated(unresolvedImage)) {
      return
    }

    try {
      this.image = await this.fetchImage(unresolvedImage)
    } catch (error) {
      const message: string =
        'CardModuleUi.fetchCardsImage(): Failed to fetch the related image from the [RelatedService]!'
      log(message, 'error')
    }
  }
}

export default CardModuleUi
