







import { Component } from 'vue-property-decorator'

import { AbstractModuleUi } from '../../_abstract'

import { TextModule } from '../Text.contracts'

/**
 * Container component for the `TextModuleUi`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<TextModuleUi>({
  name: 'TextModuleUi',
  components: {
    TextModuleUiPresentation: () => import(
      /* webpackChunkName: "modules/shared" */
      './Text.ui.presentation.vue'
    )
  }
})
export class TextModuleUi extends AbstractModuleUi<TextModule> {}

export default TextModuleUi
