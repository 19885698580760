













import { BootstrapButton, BootstrapLink } from '@movecloser/ui-core'
import { Component, Mixins, Prop } from 'vue-property-decorator'

import { Alignmentable, Colorable } from '../../../../extensions'
import { LinkWithLabel } from '../../../../contracts'

import { AbstractModuleUiPresentation } from '../../_abstract'

import { BUTTON_MODULE_THEME } from '../Button.config'
import { ButtonModuleContent } from '../Button.contracts'

/**
 * Presentational component for the `ButtonModuleUi`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<ButtonModuleUiPresentation>({
  name: 'ButtonModuleUiPresentation',
  components: { BootstrapButton, BootstrapLink }
})
export class ButtonModuleUiPresentation extends
  Mixins<AbstractModuleUiPresentation, Alignmentable, Colorable>(AbstractModuleUiPresentation, Alignmentable, Colorable) {
  /**
   * @see ButtonModuleContent.link
   */
  @Prop({ type: Object, required: true })
  public readonly link!: LinkWithLabel

  /**
   * @see ButtonModuleContent.outline
   */
  @Prop({ type: Boolean, required: false, default: false })
  public readonly outline!: boolean

  /**
   * @see ButtonModuleContent.theme
   */
  @Prop({ type: String, required: false, default: BUTTON_MODULE_THEME.Primary })
  public readonly theme!: ButtonModuleContent['theme']

  /**
   * @see ButtonModuleContent.uppercase
   */
  @Prop({ type: Boolean, required: false, default: false })
  public readonly uppercase!: boolean

  /**
   * Determines whether there's a valid link to render.
   */
  public get hasLink (): boolean {
    return typeof this.link !== 'undefined' &&
      typeof this.link.target !== 'undefined' &&
      typeof this.link.label === 'string' &&
      this.link.label.length > 0
  }

  /**
   * Determines whether the button should be rendered as a regular link.
   */
  public get isLink (): boolean {
    return this.theme === BUTTON_MODULE_THEME.Link
  }
}

export default ButtonModuleUiPresentation
