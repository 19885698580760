








import { Component } from 'vue-property-decorator'
import { EventbusType, IEventbus } from '@movecloser/front-core'

import { AbstractModuleUi } from '../../_abstract'

import { TabsModule } from '../Tabs.contracts'

/**
 * Container component for the `TabsModuleUi`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<TabsModuleUi>({
  name: 'TabsModuleUi',
  components: {
    TabsModuleUiPresentation: () => import(
      /* webpackChunkName: "modules/shared" */
      './Tabs.ui.presentation.vue'
    )
  }
})
export class TabsModuleUi extends AbstractModuleUi<TabsModule> {
  /**
   * An instance of the `EventBus` service.
   */
  public get eventBus (): IEventbus {
    return this.resolveInjection<IEventbus>(EventbusType)
  }

  /**
   * Determines whether the component should be rendered.
   */
  public get shouldRender (): boolean {
    return typeof this.data !== 'undefined' &&
      typeof this.data.content !== 'undefined' &&
      typeof this.data.version === 'string' &&
      typeof this.eventBus !== 'undefined'
  }
}

export default TabsModuleUi
