









import { AnyObject, EventPayload, IEventbus } from '@movecloser/front-core'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { debounce, DebouncedFunc, throttle } from 'lodash'

import { UI_CONTAINER_ID_ATTR_PREFIX } from '../../../../../atoms'

import { TOGGLE_NAVBAR_STATE_EVENT } from '../../../../Navbar'

import { TabsModuleContainersRegistry, TabsModuleContent } from '../../../Tabs.contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (original)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (edited)
 */
@Component<TabsScrollspy>({
  name: 'TabsScrollspy',
  components: {
    Tablist: () => import(
      /* webpackChunkName: "modules/shared" */
      '../Tablist/Tablist.vue'
    )
  },
  mounted (): void {
    this.freezeParentHeight()
    this.registerWatchers()
    this.handleNavbarState()
  },
  beforeDestroy (): void {
    this.unregisterWatchers()
  }
})
export class TabsScrollspy extends Vue {
  /**
   * @see TabsModuleUiPresentation.containers
   */
  @Prop({ type: Object, required: true })
  private readonly containers!: TabsModuleContainersRegistry

  /**
   * An instance of the `EventBus` service.
   */
  @Prop({ type: Object, required: true })
  private readonly eventBus!: IEventbus

  /**
   * @see TabsModuleContent.tabs
   */
  @Prop({ type: Array, required: true })
  public readonly tabs!: TabsModuleContent['tabs']

  /**
   * Determines whether the containersObserver is currently paused.
   *
   * @see containersObserver
   */
  private isContainersObserverPaused: boolean = false

  /**
   * ID of the currently-active (visible) container.
   */
  private mActiveContainerId: string = this.tabs[0].containerId

  /**
   * @see TabsScrollspy.mActiveContainerId
   */
  public get activeContainerId (): TabsScrollspy['mActiveContainerId'] {
    return this.mActiveContainerId
  }

  /**
   * @see TabsScrollspy.mActiveContainerId
   */
  public set activeContainerId (containerId: TabsScrollspy['mActiveContainerId']) {
    this.mActiveContainerId = containerId
  }

  /**
   * Determines whether the component is sticky at the given moment.
   */
  public isSticky: boolean = false

  /**
   * Determines whether module should have space in top
   */
  public hasSpaceTop: boolean = false

  /**
   * Handler for the 'scroll' event responsible for updating the tablist position.
   */
  private tablistWatcher: DebouncedFunc<() => void> | null = null

  /**
   * Handles the `@update:activeContainerId` event on the `<Tablist>` component.
   *
   * @param containerId - ID of the activated container.
   */
  public onActiveContainerIdUpdate (containerId: TabsScrollspy['activeContainerId']): void {
    this.scrollToContainer(this.containers[containerId])
    this.activeContainerId = containerId
  }

  /**
   * Freezes the height of the component's parent HTML element to prevent the page "jumping".
   *
   * @recursive
   */
  private async freezeParentHeight (): Promise<void> {
    return new Promise(resolve => {
      if (!this.$el.parentElement) {
        return resolve()
      }

      const elHeight: number = this.$el.getBoundingClientRect().height

      if (elHeight !== 0) {
        this.$el.parentElement.style.minHeight = elHeight + 'px'
        return resolve()
      } else {
        return setTimeout(() => resolve(this.freezeParentHeight()), 300)
      }
    })
  }

  /**
   * Handles state of navbar (hidden/visible)
   */
  private handleNavbarState (): void {
    this.eventBus.handle(TOGGLE_NAVBAR_STATE_EVENT, (data: EventPayload<AnyObject>) => {
      if (data.payload) {
        this.hasSpaceTop = data.payload.newState !== 'hidden'
      }
    })
  }

  /**
   * Height (in px) of the component's root HTML element.
   */
  private getElHeight (): number {
    return (this.$el as HTMLDivElement).getBoundingClientRect().height
  }

  /**
   * Calculates the element's absolute offset from the documents very top.
   *
   * @param elem - The HTML element of subject.
   */
  private static getElemOffsetFromDocumentTop (elem: HTMLElement): number {
    return elem.getBoundingClientRect().top + document.documentElement.scrollTop
  }

  /**
   * Registers all needed watchers.
   */
  private registerWatchers (): void {
    this.watchTablist()
    this.watchContainers()
  }

  /**
   * Scrolls the viewport to the top of the passed-in container.
   *
   * @param container - The container element that the viewport should be scrolled to.
   */
  private scrollToContainer (container: HTMLDivElement): void {
    this.isContainersObserverPaused = true

    const onScroll = debounce(() => {
      this.isContainersObserverPaused = false
      window.removeEventListener('scroll', onScroll)
    }, 100)

    window.addEventListener('scroll', onScroll)

    container.setAttribute('tabindex', '-1')
    container.focus()
    container.scrollIntoView()
  }

  /**
   * "Snaps" the component to the viewport's top border.
   */
  private snap (): void {
    this.isSticky = true
  }

  /**
   * Disconnects the `IntersectionObserver` watching the containers.
   */
  private stopWatchingContainers (): void {
    if (typeof window === 'undefined' || this.containersObserver === null) {
      return
    }

    this.containersObserver.disconnect()
  }

  /**
   * Removes the watcher for the `scroll` event connected with the tablist position.
   */
  private stopWatchingWindowScroll (): void {
    if (typeof window === 'undefined') {
      return
    }

    // eslint-disable-next-line no-undef
    window.removeEventListener('scroll', this.tablistWatcher as EventListenerOrEventListenerObject)
  }

  /**
   * "Unsnap" the component from the viewport's top border (restores its original position).
   */
  private unsnap (): void {
    this.isSticky = false
  }

  /**
   * Unregisters all registered watchers.
   */
  private unregisterWatchers (): void {
    this.stopWatchingWindowScroll()
    this.stopWatchingContainers()
  }

  /**
   * Watches the `scroll` event on the `window` object and updates the height of the tablist
   * position accordingly.
   */
  private watchTablist (): void {
    if (typeof window === 'undefined') {
      return
    }

    const firstContainer = this.containers[this.tabs[0].containerId]
    const breakpoint: number = TabsScrollspy.getElemOffsetFromDocumentTop(firstContainer) - this.getElHeight()

    this.tablistWatcher = throttle(() => {
      window.scrollY >= breakpoint ? this.snap() : this.unsnap()
    }, 100)

    window.addEventListener('scroll', this.tablistWatcher)
  }

  /**
   * Watches the containers elements to enter the viewport.
   */
  private watchContainers (): void {
    if (typeof window === 'undefined') {
      return
    }

    const containers: HTMLDivElement[] = Object.values(this.containers)

    if (!containers || !Array.isArray(containers) || containers.length === 0) {
      return
    }

    this.containersObserver = new IntersectionObserver(entries => {
      if (this.isContainersObserverPaused) {
        return
      }

      entries.forEach(entry => {
        if (entry.intersectionRatio === 0) {
          return
        }

        // noinspection UnnecessaryLocalVariableJS
        const intersectingContainerId: string = entry.target.id.replace(UI_CONTAINER_ID_ATTR_PREFIX, '')
        this.activeContainerId = intersectingContainerId
      })
    }, { threshold: 0.25 })

    containers.forEach(container => this.containersObserver?.observe(container))
  }

  /**
   * `IntersectionObserver` watching the containers to enter the viewport.
   */
  public containersObserver: IntersectionObserver | null = null
}

export default TabsScrollspy
