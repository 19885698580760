// Copyright © 2021 Move Closer

import { AsyncComponent, VueConstructor } from 'vue'

import { TabsModuleVersion } from '../Tabs.contracts'

/**
 * Registry that binds the `TabsModuleVersion` with the applicable Vue component.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const tabsModuleVersionComponentRegistry: Record<TabsModuleVersion, VueConstructor | AsyncComponent> = {
  [TabsModuleVersion.Scrollspy]: () => import(
    /* webpackChunkName: "modules/shared" */
    './partials/TabsScrollspy/TabsScrollspy.vue'
  ),
  [TabsModuleVersion.Switch]: () => import(
    /* webpackChunkName: "modules/shared" */
    './partials/TabsSwitch/TabsSwitch.vue'
  )
}
