




















import { BootstrapImage, BootstrapImageProps, BootstrapLink } from '@movecloser/ui-core'
import { Component, Prop } from 'vue-property-decorator'

import { AbstractModuleUiPresentation } from '../../_abstract'
import { ResolvedLink } from '../../../../contracts'

/**
 * Presentational component for the `ImageModuleUi`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<ImageModuleUiPresentation>({
  name: 'ImageModuleUiPresentation',
  components: { BootstrapImage, BootstrapLink }
})
export class ImageModuleUiPresentation extends AbstractModuleUiPresentation {
  /**
   * The image to render.
   */
  @Prop({ type: Object, required: true })
  public readonly image!: BootstrapImageProps

  @Prop({ type: Object, required: false, default: null })
  public readonly link!: ResolvedLink | null

  /**
   * Max displayed image height.
   * @see: https://developer.mozilla.org/en-US/docs/Web/CSS/max-height
   */
  @Prop({ type: Number, required: false })
  public readonly maxHeight?: number

  /**
   * Max displayed image width.
   * @see: https://developer.mozilla.org/en-US/docs/Web/CSS/max-width
   */
  @Prop({ type: Number, required: false })
  public readonly maxWidth?: number

  @Prop({ type: String, required: true })
  public readonly htmlId!: string

  public get style (): string {
    let sizeStyle: string = ''

    if (typeof this.maxHeight !== 'undefined') {
      sizeStyle += `max-height: ${this.maxHeight}px;`
    }

    if (typeof this.maxWidth !== 'undefined') {
      sizeStyle += `max-width: ${this.maxWidth}px;`
    }

    return `#${this.htmlId} { ${sizeStyle} }`
  }
}

export default ImageModuleUiPresentation
